
if window.matchMedia('(max-width:750px)').matches
  menuState = false
  currentPositionY = 0

  # メニューボタンクリック時
  $('#menu-toggle').on('click',
    (e)->
      # bodyのclassを付け外しする
      $('body').toggleClass('is-open-menu')

      if menuState is false
        # メニューを開いた時の、スクロール位置を保持
        currentPositionY = $(window).scrollTop()
        # $('body').addClass('is-fixed').css({
        #   'top': -currentPositionY
        # })
        $('body').addClass('is-fixed')
        menuState = true
      else
        # メニューを閉じた時
        $('body').removeClass('is-fixed').css({
          'top': 0
        })
        # 元のスクロール位置に戻す
        $('body,html').animate({
          scrollTop: currentPositionY
        }, 0)
        menuState = false
  )

  # メニュー内のaタグクリック時
  $('.l-header__navi a').on('click',
    (e)->
      headerHight = $('#menu-toggle').outerHeight(true)
      targetId = $(this).attr('href')
      targetPosition = $(targetId).offset().top
      menuState = false

      console.log(targetId)
      console.log(targetPosition)

      $('body,html').animate({
        scrollTop: targetPosition - headerHight
      }, 500)

      $('body').removeClass('is-fixed').css({
        'top': 0
      })
      $('body').toggleClass('is-open-menu')
  )

  # メニュー外のaタグクリック時
  $('a').not('.l-header__navi a').on('click',
    (e)->
      headerHight = $('#menu-toggle').outerHeight(true)
      targetId = $(this).attr('href')
      targetPosition = $(targetId).offset().top

      $('body,html').animate({
        scrollTop: targetPosition - headerHight
      }, 500)
  )

  $(window).scroll(
    ()->
      footerPosition = $('.l-footer').offset().top
      footerHight = $('.l-footer').outerHeight()
      pagetop = $('.c-pagetop')
      pagetopHight = pagetop.outerHeight()

      if $(window).scrollTop() + $(window).height() - pagetopHight * 2 > footerPosition
        pagetop.addClass('is-footer')
      else
        pagetop.removeClass('is-footer')
  )

else
  $scrollbar =$('.c-scrollbar')
  $gnavi =$('.l-header')
  $gnaviItem =$('.l-header__navi__list__item')
  $navContents = $('.navi-point')
  $navContentsPos = {}

  # メニュー外のaタグクリック時
  $('a').on('click',
    (e)->
      targetId = $(this).attr('href')
      targetPosition = $(targetId).offset().top

      $('body,html').animate({
        scrollTop: targetPosition - $gnavi.outerHeight(true)
      }, 500)
  )

  $navContents.each(
    (index, val)->
      if $(this).attr('id')
        position = {}
        position[$(this).attr('id')] = $(this).offset().top
        $navContentsPos[Object.keys($navContentsPos).length] = position
  )

  $(window).scroll(
    ()->
      # pagetop & scrollbar
      footerPosition = $('.l-footer').offset().top
      footerHight = $('.l-footer').outerHeight()
      pagetop = $('.c-pagetop')
      pagetopHight = pagetop.outerHeight()

      if $(window).scrollTop() + $(window).height() - pagetopHight > footerPosition
        pagetop.addClass('is-footer')
        $scrollbar.addClass('is-hidden')
      else
        pagetop.removeClass('is-footer')
        $scrollbar.removeClass('is-hidden')

      # スクロール位置のコンテンツによってヘッダーメニュースタイルを変更するロジック
      $.each($navContentsPos,
        (key, val)->
          target = Object.keys(val)
          $target = $('#'+target)

          # -2で微調整
          if $(window).scrollTop() >= $target.position().top - $gnavi.outerHeight(true) - 2 and $(window).scrollTop() < $target.position().top + $target.outerHeight(true)
            $gnavi.find('.'+target).addClass('is-active')
            $gnaviItem.not('.'+target).removeClass('is-active')

          if $(window).scrollTop() < $($navContents.get(0)).position().top - $gnavi.outerHeight(true)
            $gnaviItem.removeClass('is-active')
      )
  )
